/* Existing CSS ... */

body.menu-open {
  overflow: hidden;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: relative;
  z-index: 1000; /* Ensure navbar is above other content */
}

.navbar-brand {
  font-size: 1.5em;
  font-weight: bold;
  color: #111;
  text-decoration: none;
}

.navbar-link {
  text-decoration: none;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-links a {
  margin-left: 20px;
  color: #111;
  text-decoration: none;
  font-weight: bold;
}


.howitworksbold {
  background: #fff;
  color: #242424 !important;
  border-radius: 25px;
  padding: 3px 10px;
}

.howitworksbold:hover {
  background-color: #f7f7f7;
}

/* Mobile Styles */
.menu-toggle {
  display: none;
  cursor: pointer;
}

.burger-menu {
  font-size: 1.5em;
}

.close-button {
  display: none;
  font-size: 2em;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .navbar-links {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #288cff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 9999; /* Ensure it overlays other content */
  }

  .navbar-links.open {
    transform: translateX(0);
  }

  .navbar-links a {
    margin: 10px 0;
    font-size: 1.5em;
  }

  .menu-toggle {
    display: block;
  }

  .close-button {
    display: block;
  }
}

/* Media Query for Desktop */
@media (min-width: 769px) {
  .close-button {
    display: none;
  }
}
