@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

.home {
  background: linear-gradient(315deg, rgb(255, 171, 249) 3%, rgb(156, 205, 255) 38%, rgb(186, 255, 250) 68%, rgb(255, 157, 157) 98%);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
}

@keyframes gradient {
  0% {
      background-position: 0% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}

.wave {
  background: rgb(255 255 255 / 25%);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 12em;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.wave:nth-of-type(2) {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}

.wave:nth-of-type(3) {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
      transform: translateX(1);
  }

  25% {
      transform: translateX(-25%);
  }

  50% {
      transform: translateX(-50%);
  }

  75% {
      transform: translateX(-25%);
  }

  100% {
      transform: translateX(1);
  }
}

.home {
  color: #111111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.home-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 70px;
  margin-top: 70px;
}

.text-content {
  max-width: 50%;
}

.text-content h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.text-content p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.buttons .btn {
  display: inline-block;
  padding: 10px 20px;
  margin-right: 10px;
  font-size: 1em;
  color: #fff;
  background: linear-gradient(135deg, rgb(136, 0, 255) 3%, rgb(0, 128, 255) 70%);
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  font-weight: 700;
}

.buttons .btn:hover {
  background-color: linear-gradient(135deg, rgb(120, 0, 225) 3%, rgb(0, 99, 199) 70%);
}

.image-content img {
  max-width: 90%;
  border-radius: 15px;
  margin-left: 50px;
}

.footer {
  margin-top: auto;
}

.wave-transition {
  background: url('https://www.svgrepo.com/show/263515/wave.svg') no-repeat center bottom;
  background-size: cover;
  height: 150px;
  width: 100%;
}

.additional-section {
  color: #111;
  padding: 150px 20px;
  text-align: center;
}

.additional-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.university-slider {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.university-slide {
  display: inline-block;
  width: 200px;
  margin-right: 20px;
}

.university-slide img {
  width: 100%;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 10));
  }
}

.university-slider {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  animation: scroll-left 20s linear infinite;
}

.additional-section2 {
  background: linear-gradient(120deg, #40cb00, #40cb00);
  color: #ffffff;
  padding: 50px 20px;
  padding-bottom: 150px;
  text-align: center;
}

.additional-section2 h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
}

.additional-section2 ul {
  align-items: center;
  font-size: 2em;
  font-weight: 600;
}

.additional-section2 p {
  align-items: center;
  font-size: 1.2em;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .home-content {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .text-content {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .image-content img {
    max-width: 90%;
    margin: 10px;
  }
}


.additional-section {
  color: #111;
  padding: 50px 20px;
  text-align: center;
  overflow: hidden;
}

.additional-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.university-slider-container {
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

.university-slider {
  display: flex;
  animation: scroll 30s linear infinite;
  width: calc(200px * 100); /* Adjust based on the number of images */
}

.university-slide {
  flex: 0 0 200px;
  height: 150px; /* Set a fixed height */
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.university-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 10)); /* Half of the total width */
  }
}

.used {
  font-size: 2.5em !important;
  margin: 100px 0 !important;
  color: #111;
}

.addslider {
  margin-bottom: 150px;
}