@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');


.tutor-list {
  min-height: 100vh;
  font-family: 'Poppins';
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 50px;
}

.tutoringall {
  background: linear-gradient(315deg, rgb(255, 171, 249) 3%, rgb(156, 205, 255) 38%, rgb(186, 255, 250) 68%, rgb(255, 157, 157) 98%);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
  font-family: 'Poppins';
}

@keyframes gradient {
  0% {
      background-position: 0% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}

/* Style for the tutor list title */
.tutor-list h1 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  color: #111;
}

/* Parent container for controls and tutorsection */
.controls-tutorsection-wrapper {
  display: flex;
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative; /* Add relative positioning for context */
}

/* Style for the controls section (sort and search) */
.controls {
  position: fixed; /* Make the controls fixed */
  top: 100px; /* Adjust the top position as needed */
  left: 0; /* Fix it to the left side */
  width: 300px; /* Set a specific width */
  background-color: #fff;
  border-radius: 15px;
  z-index: 1000; /* Ensure it stays above other content */
  padding: 20px; /* Add padding for spacing */
  margin: 80px 45px;
}

.sort-filter,
.search-filter {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 500;
  color: #444;
  margin-bottom: 20px;
  font-family: 'Poppins';
}

.input {
  margin-left: 5px;
}

.droptext {
  font-family: 'Poppins' !important;
}



.sort-filter select,
.search-filter input {
  padding: 10px;
  border: none;
  border-radius: 25px;
}

.search-filter input {
  border: 2px solid #0056b3;
  font-family: 'Poppins';
  max-width: 250px;
}

.showallbutton {
  background-color: #d9d9d9;
}

/* Style for the tutorsection container */
.tutorsection {
  width: calc(100% - 320px); /* Adjust width considering the fixed controls width + margin */
  margin-left: 320px; /* Same width as the controls */
}

/* Style for the tutors container */
.tutors {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

/* Style for individual tutor cards */
.tutor-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 100%;
  max-width: 1100px;
  text-align: left;
  transition: transform 0.2s ease;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

.tutor-card:hover {
  box-shadow: inset 0 0 0 2px #056fd2;
}

.tutor-content {
  flex: 1;
  min-width: 200px;
}

.tutor-card h2 {
  color: #444;
  margin-top: 0;
}

.tutor-card p {
  color: #666;
  margin: 10px 0;
  font-family: 'Poppins';
}

.tutor-card p strong {
  color: #333;
}

.tutor-image {
  width: 150px;
  height: auto;
  border-radius: 8px;
  margin-right: 20px;
}

.form-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Poppins';
  font-weight: 600;
}

.form-button:hover {
  background-color: #0056b3;
}

.unavailable {
  background-color: #f5f5f5;
  color: #999;
  pointer-events: none;
  opacity: 0.6;
}

.notutors {
  text-align: center;
}

.tutor-description {
  display: inline;
}

.more-text {
  cursor: pointer;
  display: inline;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
}

.subject-button {
  border-radius: 15px;
  padding: 3px;
  margin: 3px;
  font-family: 'Poppins';
}

/* New layout styles */
.tutor-card {
  display: grid;
  grid-template-areas:
    "image name"
    "image subject"
    "image location"
    "image experience"
    "description description"
    "availability availability";
  grid-template-columns: 150px 1fr;
  gap: 10px;
}

.tutor-image {
  grid-area: image;
}

.tutor-name {
  grid-area: name;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.2em;
}

.tutor-subject {
  grid-area: subject;
  align-items: center;
}

.tutor-location {
  grid-area: location;
  align-items: center;
}

.tutor-experience {
  grid-area: experience;
  align-items: center;
}

.tutor-description {
  grid-area: description;
  margin-top: 10px;
}

.tutor-availability {
  grid-area: availability;
  margin-top: 10px;
}

.tutor-card p {
  margin: 5px 0;
}

/* Ensure whitespace between labels and content */
.tutor-card p strong::after {
  content: " ";
}

@media (max-width: 768px) {
  .controls-tutorsection-wrapper {
    flex-direction: column;
  }

  .controls {
    width: 100%;
    position: static;
    margin: 5px;
  }

  .search-filter input {
    max-width: 180px;
  }

  .tutorsection {
    width: 100%;
    margin-left: 0;
  }

  .controls {
    flex-direction: column;
    gap: 10px;
  }

  .sort-filter, .search-filter {
    justify-content: center;
  }

  .tutor-card {
    grid-template-areas:
      "image"
      "name"
      "subject"
      "experience"
      "description"
      "availability";
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }

  .tutor-image {
    margin: 0 0 10px 0;
    width: 100%;
  }

  .tutor-content {
    width: 100%;
  }
}
