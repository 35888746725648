.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Enable vertical scrolling */
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%; /* Adjusted max-width */
  width: 90%;
  max-height: 90%; /* Added max-height */
  overflow-y: auto; /* Enable content scrolling if it exceeds modal height */
  position: relative;
}

.modal-content h2 {
  margin-top: 0;
  color: #333;
}

.modal-content p {
  margin: 10px 0;
  color: #666;
}

.modal-content p strong {
  color: #333;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #ff5e57;
  cursor: pointer;
  transition: color 0.3s;
}

.modal-close-button:hover {
  color: #ff3830;
}

.modaltitle {
  text-align: center;
  margin: 10px 0 !important;
}

.form-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-button:hover {
  background-color: #0056b3;
}

.zoom-button {
  margin-top: 10px;
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.zoom-button:hover {
  background-color: #0056b3;
}

/* Ensuring responsiveness */
@media (min-width: 768px) {
  .modal-content {
    max-width: 600px; /* Keep max-width on larger screens */
  }
}
