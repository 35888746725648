@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Ensures padding and border are included in element's total width and height */
}

body {
    height: 100vh; /* Ensure body covers the entire viewport height */
    font-family: 'Poppins';
}

.about {
    background: linear-gradient(315deg, rgb(255, 171, 249) 3%, rgb(156, 205, 255) 38%, rgb(186, 255, 250) 68%, rgb(255, 157, 157) 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
  }
  
  @keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
  }

.about {
    font-family: 'Poppins';
    color: #111;
}

.about-content {
    max-width: 800px;
    margin: 70px auto;
    text-align: center;
    padding: 20px;
}

.about h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.about p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.about-info {
    text-align: left;
}

.about-info h2 {
    font-size: 2em;
    margin-bottom: 10px;
}

.about-info ul {
    list-style-type: disc;
    padding-left: 20px;
}

.about-info ul li {
    font-size: 1.1em;
    margin-bottom: 10px;
}

.aboutusimg img {
    max-width: 200px;
    border-radius: 15px;
    margin-right: 10px;
}
