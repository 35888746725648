.footer {
  background: #fff;
  color: #272727;
  padding: 20px 0; /* Adjusted padding */
  padding-top: 40px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column; /* Stack items vertically by default */
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-links {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping */
  justify-content: center; /* Center links */
  gap: 20px;
  margin-bottom: 20px; /* Space between links and info */
}

.footer-links a {
  color: #292929;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #2584ff;
}

.footer-info {
  text-align: center; /* Center align text */
}

.footer-info p {
  margin: 0;
  font-size: 14px;
}

/* Media Query for Larger Screens */
@media (min-width: 768px) {
  .footer-content {
    flex-direction: row; /* Align items horizontally on larger screens */
    align-items: center;
    justify-content: space-between; /* Spread items out */
  }

  .footer-info {
    text-align: right; /* Align text to the right on larger screens */
  }
}
