/* src/components/How.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.how {
    background: linear-gradient(315deg, rgb(255, 171, 249) 3%, rgb(156, 205, 255) 38%, rgb(186, 255, 250) 68%, rgb(255, 157, 157) 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
  }
  
  @keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
  }

.how {
    font-family: 'Poppins';
    overflow: hidden; /* Prevent overflow */
}

.how-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    font-family: 'Poppins';
}

.how h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.how p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.how {
    color: #333;
}

.how-content {
    padding: 20px;
    margin-bottom: 70px;
    margin-top: 70px;
}

.how-content h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
}

.additional-section2 {
    margin-top: 30px;
}

.additional-section2 h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.slide {
    text-align: center;
    padding: 20px 30px;
}

.slide h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.slide p {
    font-size: 1.2rem;
    line-height: 1.6;
}

.slide img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
}

.slick-dots {
    bottom: 20px;
}

.slick-dots li button:before {
    font-size: 14px;
    color: #999;
}

.works {
    background: #ffffff;
    color: #2b2b2b;
    padding: 50px 20px;
    padding-bottom: 50px;
    text-align: center;
    border-radius: 15px;
}

.works h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
}

.works ul {
    align-items: center;
    font-size: 2em;
    font-weight: 600;
}

.works p {
    align-items: center;
    font-size: 1.2em;
    margin-bottom: 30px;
}

.slick-next, .slick-prev {
    display: none !important; /* Hide default arrows */
}

@media (min-width: 768px) {
    .slick-next, .slick-prev {
        display: block !important;
        background-color: black; /* Black background for arrows */
        color: white; /* White color for the arrow icons */
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .slick-next {
        right: 10px !important;
    }

    .slick-prev {
        left: 10px !important;
    }
}

.workimg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 30% !important;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 40px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.worksh2 {
    font-size: 2.5em !important;
    text-align: center;
    color: #111;
    margin: 30px 0 !important;
}
