@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Ensure body covers the entire viewport height */
body {
    height: 100vh;
    margin: 0; /* Remove default margin to avoid unnecessary space */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.contact {
    background: linear-gradient(315deg, rgb(255, 171, 249) 3%, rgb(156, 205, 255) 38%, rgb(186, 255, 250) 68%, rgb(255, 157, 157) 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
  }
  
  @keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
  }

.contact {
    font-family: 'Poppins', sans-serif;
    color: #111;
    min-height: 100vh; /* Ensure the contact page covers the entire viewport */
    display: flex;
    flex-direction: column;
    text-align: left;
}

.contact-content {
    max-width: 1000px; /* Adjusted max-width for larger screens */
    margin: 0 auto;
    text-align: center;
    padding: 20px; /* Added padding for better spacing */
}

.contact h1 {
    font-size: 2.5em;
    margin: 30px 0;
    text-align: left;
}

.contact p {
    font-size: 1.2em;
    margin-bottom: 20px;
    text-align: left;

}

/* Responsive adjustments */
@media (max-width: 768px) {
    .contact-content {
        padding: 10px; /* Adjusted padding for smaller screens */
    }

    .contact h1 {
        font-size: 2em; /* Decreased font size for smaller screens */
    }

    iframe {
        width: 100%;
    }

    .iframecontact {
      min-height: 1600px;
    }
}

.iframecontact {
  min-height: 1300px;
}